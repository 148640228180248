<template>
  <div class="min-height position-relative flex-column justify-content-start align-items-center">
    <img src="@/assets/image/pc/1.jpg" width="100%" alt="" />

    <div class="block3 br-mid flex-column justify-content-start align-items-center">
      <div class="width-100 margin-bs flex-row justify-content-spaceBetween align-items-center">
        <div class="width-60 title flex-row justify-content-start align-items-center">
          <p class="margin-r">报告人信息</p>
          <img src="@/assets/image/pc/5.jpg" height="20rem" alt="" />
        </div>
        <div @click="jumpTo('/pcForm')" class="width-40 textAlign-r">
          <img src="@/assets/image/pic1.png" height="30rem" alt="" />
        </div>
      </div>
      <div @click="jumpToInfo(item.id)" class="list-box position-relative margin-bs" v-for="(item, index) in list" :key="index">
        <div v-if="item.reportStatus === 1" class="label label1 white position-absolute">处理中</div>
        <div v-else-if="item.reportStatus === 2" class="label label2 white position-absolute">已办结</div>
        <div v-else class="label label3 white position-absolute">未回复</div>
        <div class="width-70 flex-row justify-content-start align-items-center">
          <p class="fs-mid black margin-rs fw-mid">{{ item.childName }}</p>
          <p class="fs-mid darkgrey margin-ls">{{ item.createTime }}</p>
        </div>
        <p class="fs-mid width-100 darkgrey margin-ts cut-text">就诊病例：{{ item.diagnoses }}</p>
        <p class="fs-mid darkgrey margin-ts cut-text2">{{ item.reportContent }}</p>
      </div>
    </div>
    <div class="width-100 bg-slightGrey fs-sml darkgrey footer position-absolute textAlign-c">
      版权所有 © 浙江省瑞安市人民检察院 备案号：京ICP备10217144号-1
    </div>
  </div>
</template>
<script>
import { mandatoryReportList } from '@/api/reportApi';

export default {
  name: 'mandatoryReportingIndex',
  data() {
    return {
      zlbId: '',
      userName: '',
      userMobile: '',
      list: []
    };
  },
  created() {
    let zlbId = this.$Cookies.get('zlbId');

    if (zlbId == null) {
      this.$router.push('/pcIndex');
    }

    this.zlbId = zlbId;
    this.userName = this.$Cookies.get('userName');
    this.userMobile = this.$Cookies.get('userMobile');
    this.initListData(zlbId);
  },
  methods: {
    jumpTo(e) {
      this.$router.push(e);
    },
    jumpToInfo(id) {
      this.$router.push({
        path: '/pcInfo',
        query: {
          id: id
        }
      });
    },
    initListData(zlbId) {
      mandatoryReportList({ zlbId: zlbId }).then((response) => {
        this.list = response.data;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.block3 {
  padding: 2rem 5%;
  width: 70%;
  min-width: 600px;
  padding-bottom: 10rem;

  .title {
    line-height: 2rem;
    //background: url("../../assets/image/5.png") left bottom no-repeat;
    //background-size: contain;
    p {
      //color: #4f9eea;
      font-size: 1.2rem;
    }
  }
  .list-box {
    width: 100%;
    padding: 1rem;
    background: rgb(244, 249, 255);
    .label {
      right: 0;
      top: 0.5rem;
      font-size: 0.8rem;
      padding: 0.1rem 0.5rem 0.1rem 1rem;
    }
    .label1 {
      background: url('../../assets/image/pic3.png') center no-repeat;
      background-size: 100%;
    }
    .label2 {
      background: url('../../assets/image/pic4.png') center no-repeat;
      background-size: 100%;
    }
    .label3 {
      background: url('../../assets/image/pic5.png') center no-repeat;
      background-size: 100%;
    }
  }
}
/deep/.van-hairline--top-bottom::after {
  border: none;
}
/deep/.van-cell {
  //background: #fafafa;
  //padding: 6px 16px;
  //border-bottom: 1px solid #eeeeee;
  margin-bottom: 10px;
  padding: 10px 0;
  display: flex;
  justify-content: start;
  align-items: center;
}
/deep/.van-cell::after {
  border: none;
}
/deep/.van-field__control {
  border: 1px solid #eeeeee;
  padding: 5px 16px;
}
/deep/.van-field__control--custom {
  border: none !important;
}

.footer {
  padding: 3rem;
  bottom: 0;
}
</style>
